import { useRef } from 'react';
import { T_Slot } from '../../../types';
import { useAppDispatch } from '../../hooks';
import { useHitboxSetter } from './hooks/useHitboxSetter';

export const SlotHitbox = ({ slot }: { slot: T_Slot }) => {
    const ref = useRef<HTMLDivElement>(null);
    useHitboxSetter({ id: slot.id, ref, hitboxType: 'slot' });
    return (
        <>
            <div
                ref={ref}
                style={{
                    paddingTop: '15vh',
                    borderRadius: '5px, 0px, 0px, 5px',
                    opacity: slot.keyAboveSlot ? 0.5 : 0.01,
                    backgroundColor: slot.keyAboveSlot ? 'green' : 'none',
                }}
            ></div>
        </>
    );
};
