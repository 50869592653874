import React from 'react';
import { DemoLogo } from '../components/Assets';
import { YellowBlack } from '../components/Assets/YellowBlack';
import { usePage } from '../hooks';
import { useMediaDimensions } from '../hooks/useMediaDimensions';

export const StartWrapper = ({ children }: { children: React.ReactNode }) => {
    const { query } = useMediaDimensions();
    const page = usePage();
    return (
        <div className="start-wrapper">
            <div className="start-image" />
            <DemoLogo />
            {query || (
                <div className="top-metal-wrapper">
                    <img className="top-metal" src="/images/metal.jpg" />
                </div>
            )}
            {query || <YellowBlack />}
            <div className="black-opacity-layer" />
            <div
                className={
                    page === 'Intro' ? 'intro-inner-layer' : 'start-inner-layer'
                }
            >
                {children}
            </div>
        </div>
    );
};
