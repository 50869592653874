import { useScreen } from '../hooks/useScreen';
import { Letris, Plac } from '../screens';
import { Rario } from '../screens/Rario';
import { RarioRoute } from '../screens/Rario/RarioRoute';

export const InScreen = () => {
    return (
        <>
            <RenderInScreen />
        </>
    );
};

const RenderInScreen = () => {
    const screen = useScreen();
    switch (screen) {
        case 'LETRIS':
            return <Letris />;
        case 'PLAC':
            return <Plac />;
        case 'RARIO':
            return <Rario />;
        case 'HINTS':
            return null;
        case 'RARIOROUTE':
            return <RarioRoute />;
        default:
            return <Letris />;
    }
};
