import { useEffect, useState } from 'react';
import { getImageFromScreen } from '../../functions';
import { useAppDispatch, useAppSelector, useScreen } from '../../hooks';
import { useTimedCallback } from '../../hooks/useTimedCallback';
import { resetPlacManDraw } from '../../store/appState';
import { useCanvas } from './CanvasContext';

export function PlacCanvas() {
    const canvas = useCanvas() as any;
    const screen = useScreen();
    const [clear, setClear] = useState(false);
    const placManDraw = useAppSelector((state) => state.appState.placMan);
    const placManLoaded = useAppSelector(
        (state) => state.appState.placManLoaded,
    );
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!canvas) return;
        const { clearCanvas } = canvas;
        clearCanvas(getImageFromScreen(screen));
    }, [screen]);
    useEffect(() => {
        if (!clear) {
            if (!canvas) return;
            const { clearCanvas } = canvas;
            clearCanvas(getImageFromScreen(screen));
            setClear(true);
        }
    }, [clear]);
    useEffect(() => {
        if (!canvas || !placManLoaded) return;
        const { retrieveDrawing } = canvas;
        retrieveDrawing(placManDraw);
    }, [placManLoaded, canvas, screen, clear]);

    useTimedCallback({
        callback: () => {
            if (!canvas) return;
            const { retrieveDrawing } = canvas;
            retrieveDrawing(placManDraw);
        },
        duration: 150,
    });

    const onClick = () => {
        setClear(false);
        dispatch(resetPlacManDraw());
    };
    if (!canvas) return null;
    const { canvasRef, startDrawing, finishDrawing, draw } = canvas;

    return (
        <>
            {' '}
            <div className="screen-placman">
                <div id="refresh" className="refresh">
                    <span onClick={() => onClick()}>↻ Ververs</span>
                </div>
                <div className="screen-placman-wrapper">
                    <canvas
                        width={window.innerWidth}
                        height={window.innerHeight}
                        className="canvas-placman"
                        onMouseDown={startDrawing}
                        onTouchStart={startDrawing}
                        onMouseUp={finishDrawing}
                        onTouchEnd={finishDrawing}
                        onMouseMove={draw}
                        onTouchMove={draw}
                        ref={canvasRef}
                    />
                </div>
            </div>
        </>
    );
}
