import { SoundIcon } from '../components/SoundIcon';
import { formatTime } from '../functions';
import { useAppDispatch, useAppSelector, useRoom, useScreen } from '../hooks';
import {
    handleMute,
    setDragScroll,
    setRoom,
    setScreen,
    toggleMouseScroll,
} from '../store/appState';
import { InfoButton } from './InfoButton';

export const Header = () => {
    const time = useAppSelector((state) => state.appState.timer);
    const dispatch = useAppDispatch();
    const hintTime = time % 300;
    const countDown = formatTime(time);
    const hintCountDown = formatTime(hintTime);
    const hint = useAppSelector((state) => state.appState.unlockedHint);
    const room = useRoom();
    const screen = useScreen();
    const preVictory = useAppSelector((state) => state.appState.preVictory);
    const mouseScroll = useAppSelector((state) => state.appState.inScroll);
    const handleSoundClick = () => {
        dispatch(handleMute());
    };
    const onHintClick = () => {
        dispatch(setScreen('HINTS'));
    };
    const onChronoDecoderClick = () => {
        if (preVictory) return;
        if (room !== 'CHRONO') {
            dispatch(setRoom('CHRONO'));
        }
        if (room === 'CHRONO') {
            dispatch(setRoom('Arcade'));
        }
    };
    return (
        <>
            {screen === null && (
                <div
                    className="sound-icon-top"
                    onClick={() => handleSoundClick()}
                >
                    <SoundIcon />
                </div>
            )}
            <div
                className="countdown"
                onMouseUp={() => {
                    dispatch(toggleMouseScroll(false));
                }}
                onMouseMove={(e) => {
                    if (
                        !mouseScroll ||
                        isNaN(e.movementX) ||
                        isNaN(e.movementY)
                    )
                        return;
                    dispatch(setDragScroll({ x: e.movementX, y: e.movementY }));
                }}
            >
                <span className="clock">{countDown}</span>
                <span className="btn" onClick={() => onChronoDecoderClick()}>
                    {room === 'CHRONO' ? 'Terug' : 'Chrono Decoder'}
                </span>
            </div>

            <div className="hint">
                <InfoButton />
                {hint > 4 || (
                    <span className="count">
                        {time === 900
                            ? '05:00'
                            : time < 0
                            ? '00:00'
                            : hintCountDown}
                    </span>
                )}

                <span className="link" onClick={() => onHintClick()}>
                    <Lock hint={hint} />
                    {hint > 4 || <span>Hint {hint}/4</span>}
                    {hint > 4 && <span>Hints</span>}
                </span>
            </div>
        </>
    );
};

const Lock = (props: { hint: number }) => {
    const { hint } = props;
    if (hint < 4) {
        return (
            <svg
                id="Laag_1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 397 532"
            >
                <path d="m350.47,218.95v-66.97C350.47,68.18,282.29,0,198.5,0S46.53,68.18,46.53,151.97v66.97H0v313.06s397,0,397,0V218.95h-46.53Zm-59.25,0H105.78v-66.97c0-51.12,41.59-92.72,92.72-92.72s92.72,41.59,92.72,92.72v66.97Z" />
            </svg>
        );
    }
    return null;
};
