import { useRef } from 'react';
import Draggable from 'react-draggable';
import { T_LetrisLetters } from '../../../types';
import { getImageFromBlock } from '../../functions';
import { getStartPositions } from '../../functions/getStartPositions';
import { getWidthFromBlock } from '../../functions/getWidthFromBlock';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { dragBlock } from '../../store/appState';
export const LetrisBlock = ({
    id,
    letrisRef,
    border,
}: {
    id: T_LetrisLetters;
    letrisRef: React.RefObject<HTMLDivElement>;
    border: number | undefined;
}) => {
    const image = getImageFromBlock(id);

    const { left, top } = getStartPositions(id);
    const position = useAppSelector((state) =>
        state.appState.letrisBlocks.find((block) => block.id === id),
    );
    const letrisReset = useAppSelector((state) => state.appState.letrisReset);
    const dispatch = useAppDispatch();
    const blockRef = useRef<HTMLDivElement>(null);
    if (!position) return null;
    const selfX = blockRef.current?.getBoundingClientRect().left;

    const blockWidth = getWidthFromBlock({
        blockId: id,
        enlarged: selfX && border ? selfX < border : false,
    });
    const handleDrag = (e: any) => {
        document.body.style.cursor = 'none';
        dispatch(
            dragBlock({
                transform: blockRef.current?.style.transform,
                id,
            }),
        );
    };
    const zeroPoint = letrisRef.current?.getBoundingClientRect();
    return (
        <Draggable
            defaultPosition={{ x: position.x, y: position.y }}
            nodeRef={blockRef}
            enableUserSelectHack={true}
            onStop={() => {
                document.body.style.cursor = 'default';
            }}
            onDrag={handleDrag}
            // position={position || undefined}
            bounds={
                zeroPoint && {
                    left: -(
                        (zeroPoint.width *
                            Number(
                                getStartPositions(id).left.replace('%', ''),
                            )) /
                        100
                    ),
                    right:
                        (zeroPoint.width *
                            (100 -
                                Number(
                                    getStartPositions(id).left.replace('%', ''),
                                ))) /
                            100 -
                        30,
                    top: -(
                        (zeroPoint.height *
                            Number(
                                getStartPositions(id).top.replace('%', ''),
                            )) /
                        100
                    ),
                    bottom:
                        (zeroPoint.height *
                            (100 -
                                Number(
                                    getStartPositions(id).top.replace('%', ''),
                                ))) /
                            100 -
                        30,
                }
            }
        >
            <div
                draggable={false}
                // onMouseDown={() => dispatch(setLetrisDrag(id))}
                // onMouseUp={() => dispatch(setLetrisDrag(null))}
                // onTouchStart={() => dispatch(setLetrisDrag(id))}
                // onTouchEnd={() => dispatch(setLetrisDrag(null))}
                ref={blockRef}
                style={{
                    position: 'absolute',
                    left,
                    top,
                    width: blockWidth,
                    display: 'flex',
                }}
            >
                <img
                    draggable={false}
                    src={image}
                    loading="lazy"
                    alt="ALT TAG"
                />
            </div>
        </Draggable>
    );
};
