import { useEffect, useState } from 'react';
import { formatTime } from '../../functions';
import { useAppSelector } from '../../hooks';
import { useTimedCallback } from '../../hooks/useTimedCallback';
import { useCanvas } from '../Plac/CanvasContext';

export function EndCanvas() {
    const canvas = useCanvas() as any;
    const [clear, setClear] = useState(false);
    const timer = useAppSelector((state) => state.appState.timer);
    const endTime = formatTime(Math.abs(900 - timer));

    useEffect(() => {
        if (!clear) {
            if (!canvas) return;
            const { drawEndCanvas } = canvas;
            drawEndCanvas('/images/endscreen/endscreen_bg.png');
            setClear(true);
        }
    }, [clear]);

    useTimedCallback({
        callback: () => {
            if (!canvas) return;
            const { drawTime } = canvas;
            drawTime(endTime);
        },
        duration: 1000,
    });
    if (!canvas) return null;
    const { canvasRef, exportCanvas } = canvas;

    return (
        <div className="canvas-end-wrapper">
            <canvas
                className="canvas-end"
                ref={canvasRef}
                style={{
                    width: 900,
                    height: 1600,
                }}
            />
            <ShareButton exportCanvas={() => exportCanvas({ time: endTime })} />
        </div>
    );
}

const ShareButton = ({ exportCanvas }: { exportCanvas: Function }) => {
    if (!navigator.share) return null;
    return (
        <>
            <button className="share-button" onClick={() => exportCanvas()}>
                Deel
            </button>
        </>
    );
};
