import { Chronodecoder } from '../components/Chronodecoder/Chronodecoder';
import { useRoom } from '../hooks/useRoom';
import { Arcade } from '../rooms/Arcade';
import { Tunnel } from '../rooms/Tunnel';

export const InGame = () => {
    return (
        <>
            <RenderInGame />
        </>
    );
};

const RenderInGame = () => {
    const room = useRoom();

    switch (room) {
        case 'Tunnel':
            return <Tunnel />;
        case 'Arcade':
            return <Arcade />;
        case 'CHRONO':
            return <Chronodecoder />;
        default:
            return <Arcade />;
    }
};
