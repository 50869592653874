import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { muteSound } from '../functions/muteSound';
import { useAppDispatch } from '../hooks';
import { useSound } from '../hooks/useSound';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { setPage } from '../store/appState/slice';
import { DemoLogo } from './Assets';

export const InVideo = () => {
    const [videoTime, setVideoTime] = useState(0);
    const dispatch = useAppDispatch();
    const muted = useSound();
    const { width } = useWindowDimensions();
    useEffect(() => {
        muteSound();
    }, []);
    const onEndedEvent = () => {
        dispatch(setPage('Intro'));
    };
    const handleProgress = (e: { timeStamp: number }) => {
        setVideoTime(e.timeStamp);
    };
    //TODO: FRAMER MOTION FOR DEMO LOGO OPACITY FADE OUT
    return (
        <div className="start-wrapper">
            <AnimatePresence>
                {(videoTime > 9500 && width <= 1500) || <DemoLogo />}
            </AnimatePresence>
            <div
                style={{
                    padding: '4px',
                    width: '96%',
                    marginTop: width > 600 ? '0px' : '15%',
                    height: width > 600 ? '96%' : '86%',
                }}
            >
                <video
                    playsInline={true}
                    disablePictureInPicture={true}
                    disableRemotePlayback={true}
                    onEnded={() => onEndedEvent()}
                    onTimeUpdate={handleProgress}
                    muted={muted}
                    width="100%"
                    height="100%"
                    autoPlay={true}
                    src="https://cdn.escaperoomthegame.com/video/arcadeDemo.mp4"
                />
            </div>
        </div>
    );
};
