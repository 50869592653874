import { Howl } from 'howler';
import { T_SoundKey } from '../sounds';
export const playSound = (source: T_SoundKey, time?: number) => {
    const sound = new Howl({
        src: source,
        volume: 0.5,
        html5: true,
        onend: function () {},
    });
    if (time) {
        sound.seek(time);
    }
    sound.play();
    return;
};
