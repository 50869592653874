import { motion } from 'framer-motion';

export const DemoLogo = () => {
    return (
        <>
            <MotionLogo
                className="demo-logo-wrapper"
                animate={{ opacity: 1, y: 0 }}
                exit={{
                    opacity: 0,
                    y: -100,
                    transition: { duration: 0.25, ease: 'easeInOut' },
                }}
            >
                <img src="images/ertg-demo.png" className="demo-logo" />
            </MotionLogo>
        </>
    );
};

const MotionLogo = motion.div;
