import { useState } from 'react';
import { EndText } from '../components/EndText';
import { EndTitle } from '../components/EndTitle';
import { formatTime } from '../functions';
import { useAppDispatch, useAppSelector } from '../hooks';
import useWindowDimensions from '../hooks/useWindowDimensions';
import { setPage } from '../store/appState';

export const End = () => {
    const windowDimensions = useWindowDimensions();
    const timer = useAppSelector((state) => state.appState.timer);
    const time = formatTime(900 - timer);
    const [input, setInput] = useState<{
        firstName: string;
        mail: string;
    }>({
        firstName: '',
        mail: '',
    });
    const [requestSuccess, setRequestSuccess] = useState<boolean>(false);

    return (
        <div
            style={{
                border: 'solid 2px #fff353',
                width: '96vw',
                height: '96vh',
                marginTop: '2vh',
                marginBottom: '2vh',
                marginLeft: '2vw',
                marginRight: '2vw',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '100%',
                    height: '100%',
                    paddingTop: '30vh',
                    padding: '20px',
                    textAlign: 'center',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        height: '60%',
                    }}
                >
                    <EndTitle />
                    <EndText />
                    <form
                        style={{
                            position: 'relative',
                            paddingTop:
                                windowDimensions.width <= 600 ? '5vh' : '0',
                            transform:
                                windowDimensions.width <= 600
                                    ? 'translateY(5vh)'
                                    : 'translateY(7vh)',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '5vh',
                        }}
                    >
                        <input
                            type="text"
                            placeholder="Voornaam"
                            className="input-field"
                            onChange={(e) =>
                                setInput((v) => {
                                    return { ...v, firstName: e.target.value };
                                })
                            }
                        />
                        <input
                            type="text"
                            placeholder="E-mailadres"
                            className="input-field"
                            style={{
                                color: 'white',
                                outline: 'none',
                                height: '5vh',
                                background: 'transparent',
                                border: 0,
                                borderBottom: '2px solid white',
                            }}
                            onChange={(e) =>
                                setInput((v) => {
                                    return { ...v, mail: e.target.value };
                                })
                            }
                        />
                    </form>
                </div>
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {!requestSuccess ? (
                        <Button
                            setRequestSuccess={setRequestSuccess}
                            firstName={input.firstName}
                            mail={input.mail}
                        />
                    ) : (
                        'Bedankt voor het achterlaten van je gegevens!'
                    )}
                </div>
            </div>
        </div>
    );
};

const Button = ({
    firstName,
    mail,
    setRequestSuccess,
}: {
    firstName: string;
    mail: string;
    setRequestSuccess: Function;
}) => {
    const dispatch = useAppDispatch();

    const onClickEvent = async (e: any) => {
        e.preventDefault();
        try {
            console.log({ firstName, mail });
            await fetch(
                'https://panel.escaperoomthegame.com/api/save-arcade-mail',
                {
                    method: 'POST',
                    body: JSON.stringify({
                        FNAME: firstName,
                        email: mail,
                    }),
                },
            ).then((res) => {
                if (res.ok) {
                    setRequestSuccess(true);
                    dispatch(setPage('EndShare'));
                }
                res.json();
            });
        } catch {
            console.log('error');
        }
    };
    return (
        <div
            style={{
                height: '80px',
                width: '60%',
                border: 'solid',
                borderWidth: '2px',
                borderColor: '#fff353',
                borderRadius: '25px',
                background: '#4d226f',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease',
                position: 'relative',
            }}
            onClick={(e: any) => onClickEvent(e)}
        >
            {' '}
            <p
                style={{
                    fontSize: '32px',
                    color: '#fff353',
                    fontFamily: 'Bauhaus',
                }}
            >
                VERSTUUR
            </p>
        </div>
    );
};
