import { useScreen } from '../hooks';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useMediaDimensions } from '../hooks/useMediaDimensions';
import { usePage } from '../hooks/usePage';
import { useRoom } from '../hooks/useRoom';
import { leaveScreen, setRoom } from '../store/appState';

export const Controls = () => {
    const room = useRoom();
    const page = usePage();
    const dispatch = useAppDispatch();
    return (
        <div className="controls">
            {room === 'Arcade' && page === 'InGame' && (
                <span
                    id="tunnel-cta"
                    onClick={() => dispatch(setRoom('Tunnel'))}
                >
                    {'<'} Kelder
                </span>
            )}

            {room === 'Tunnel' && page === 'InGame' && (
                <span
                    id="arcade-cta"
                    className="active"
                    onClick={() => dispatch(setRoom('Arcade'))}
                >
                    Arcadehal {'>'}
                </span>
            )}
            {page === 'InScreen' && (
                <>
                    <span
                        id="back-cta"
                        className="active"
                        onClick={() => dispatch(leaveScreen())}
                    >
                        {'<'} Terug
                    </span>
                </>
            )}
        </div>
    );
};
