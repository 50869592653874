import { T_Screen } from '../../types';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { openDoor, setScreen } from '../store/appState';

export const SelectionBox = (props: {
    id: string;
    screen?: T_Screen;
    doorOpen?: boolean;
}) => {
    const { id, screen, doorOpen } = props;
    const dispatch = useAppDispatch();
    const handleOnClick = () => {
        if (!screen) {
            dispatch(openDoor());
            return;
        }
        dispatch(setScreen(screen));
    };
    const opacity = screen ? '0.3' : doorOpen ? '1' : '0';
    return (
        <a
            style={{ opacity }}
            id={id}
            className={id}
            onClick={() => handleOnClick()}
        ></a>
    );
};
