import Draggable from 'react-draggable';
import { T_Key } from '../../../types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import {
    handleKeyDrag,
    handleKeyDragStop,
} from '../../store/chronoState/slice';
import { useKey } from './hooks/useKey';

export const KeyNew = ({ keyObject }: { keyObject: T_Key }) => {
    const key = useKey(keyObject.id);
    const dispatch = useAppDispatch();
    const keyDimensions = useAppSelector(
        (state) => state.chronoState?.keyDimensions,
    );
    const containerBottom = useAppSelector(
        (state) => state.chronoState?.slots[0].hitbox.yTop,
    );
    if (!key) return null;
    const onStop = (e: any) => {
        let xPos;
        let yPos;
        if (e && e.changedTouches && e.changedTouches[0]) {
            xPos = e.changedTouches[0].clientX;
            yPos = e.changedTouches[0].clientY;
        }
        if (e.x && e.y) {
            xPos = e.x;
            yPos = e.y;
        }
        dispatch(
            handleKeyDragStop({ id: key.id, position: { x: xPos, y: yPos } }),
        );
    };
    const onDrag = (e: any) => {
        let xPos;
        let yPos;
        if (e && e.targetTouches && e.targetTouches[0]) {
            xPos = e.targetTouches[0].clientX;
            yPos = e.targetTouches[0].clientY;
        }
        if (e.x && e.y) {
            xPos = e.x;
            yPos = e.y;
        }
        dispatch(handleKeyDrag({ id: key.id, position: { x: xPos, y: yPos } }));
    };
    return (
        <Draggable
            key={key.id}
            position={key.position}
            onDrag={(e) => onDrag(e as MouseEvent)}
            onStop={(e) => onStop(e as MouseEvent)}
            bounds={{ bottom: containerBottom }}
        >
            <div
                style={{
                    zIndex: 20,
                    justifySelf: 'normal',
                    alignSelf: 'normal',
                    width: keyDimensions ? keyDimensions.width : '100px',
                    position: 'absolute',
                }}
            >
                <img
                    draggable={false}
                    style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                    }}
                    src={keyObject.image}
                />
            </div>
        </Draggable>
    );
};
