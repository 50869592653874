import React, { useEffect } from 'react';
import { T_Hitbox } from '../../../../types';
import { useAppDispatch } from '../../../hooks';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import { setHitbox } from '../../../store/chronoState/slice';

export const useHitboxSetter = ({
    id,
    ref,
    hitboxType,
    dependencies,
}: {
    id?: number;
    ref: React.RefObject<HTMLDivElement>;
    hitboxType: 'slot' | 'key' | 'container';
    dependencies?: any[];
}) => {
    const dispatch = useAppDispatch();
    const windowDimensions = useWindowDimensions();
    const defaultDependencies = [ref.current, windowDimensions];
    const dependencyArray = dependencies
        ? [...dependencies, ...defaultDependencies]
        : defaultDependencies;
    useEffect(() => {
        if (!ref || !ref.current) return;
        const reference = ref.current.getBoundingClientRect();
        const boundary: T_Hitbox = {
            xLeft: reference.x,
            yTop: reference.y,
            xRight: reference.x + reference.width,
            yBottom: reference.y + reference.height,
        };
        dispatch(setHitbox({ id, hitboxType, boundary }));
    }, dependencyArray);
};
