import { useAppDispatch } from '../hooks';
import { setPage, setRoom, setScreen } from '../store/appState';

export const InfoButton = () => {
    const dispatch = useAppDispatch();
    const onInfoClick = () => {
        dispatch(setRoom(null));
        dispatch(setScreen(null));
        dispatch(setPage('Intro'));
    };
    return (
        <>
            <div onClick={onInfoClick} className="info-button">
                i
            </div>
        </>
    );
};
