import useWindowDimensions from '../hooks/useWindowDimensions';

export const EndText = () => {
    //WHY ARE WE NOT ALLOWED TO USE TAILWIND AGAIN?
    const windowDimensions = useWindowDimensions();
    if (windowDimensions.width <= 600) {
        return (
            <>
                <p
                    style={{
                        transform: 'translateY(5vh)',
                        padding: '0 35px 0',
                        fontSize: '18px',
                    }}
                >
                    Ontvang meer avontuur in je mailbox
                </p>
                <p
                    style={{
                        transform: 'translateY(5vh)',
                        marginBottom: '25px',
                    }}
                >
                    en maak kans op mooie prijzen!
                </p>
            </>
        );
    }
    return (
        <p
            style={{
                transform: 'translateY(3vh)',
                padding:
                    windowDimensions.width > 900
                        ? '0 25px 0 25px'
                        : '0 25px 0 25px',
                marginBottom: '20px',
                fontSize: windowDimensions.width > 900 ? '32px' : '24px',
            }}
        >
            Ontvang meer avontuur in je mailbox en maak kans op mooie prijzen!
        </p>
    );
};
