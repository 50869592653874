import { useMediaQuery } from '@mui/material';
import { Pages } from './Pages';
import { useAppDispatch, useScreen } from './hooks';
import { Controls } from './interactions/Controls';
import { Hints } from './screens';

function App() {
    const screen = useScreen();
    return (
        <>
            {screen === 'HINTS' && <Hints />}
            <Pages />
            <Controls />
        </>
    );
}

export default App;
