import { useAppDispatch, useScreen } from '../../hooks';
import { setScreen } from '../../store/appState';

export const Switch = () => {
    const dispatch = useAppDispatch();
    const screen = useScreen();
    const className =
        screen === 'RARIO' ? 'rario-switch right' : 'rario-switch left';
    const switchHandler = () => {
        if (screen === 'RARIO') {
            dispatch(setScreen('RARIOROUTE'));
            return;
        }
        if (screen === 'RARIOROUTE') {
            dispatch(setScreen('RARIO'));
            return;
        }
    };
    return (
        <div className={className}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 35 35"
                onClick={() => switchHandler()}
                style={{}}
            >
                <circle
                    cx="17.5"
                    cy="17.5"
                    r="17.5"
                    style={{ fill: '#e172fc' }}
                />
                <path
                    d="M35 17.5C35 27.16 27.16 35 17.5 35S0 27.16 0 17.5"
                    style={{ fill: '#6e2d7f' }}
                />
                <ellipse
                    cx="17.5"
                    cy="17.5"
                    rx="17.5"
                    ry="15.5"
                    style={{ fill: '#ce08fe' }}
                />
                <path
                    d="m26.46 15.78-7-7.5c-.75-.81-2.02-.85-2.83-.1s-.85 2.02-.1 2.83L20.26 15H9.52c-1.1 0-2 .9-2 2s.9 2 2 2H20.4l-3.85 3.97c-.77.79-.75 2.06.04 2.83.39.38.89.56 1.39.56s1.04-.2 1.44-.61l7-7.22c.74-.77.75-1.98.03-2.76Z"
                    style={{ fill: '#fff' }}
                />
            </svg>
        </div>
    );
};
