import { useAppSelector } from '../../hooks';

export const SlotUpperPart = ({ left }: { left?: boolean }) => {
    const keyDimensions = useAppSelector(
        (state) => state.chronoState?.keyDimensions,
    );
    const keyHeight = useAppSelector(
        (state) => state.chronoState?.keyDimensions?.height,
    );
    const container = useAppSelector(
        (state) => state.chronoState?.containerHitbox,
    );
    if (!keyDimensions || !container) return null;
    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    bottom: keyHeight && keyHeight * 0.58,
                    left: 0,
                    height: keyHeight && keyHeight * 0.06,
                    width: '100%',
                    backgroundColor: '#2f2e2d',
                    borderTop: '2px solid #000',
                    zIndex: 10,
                }}
            />
        </>
    );
};
