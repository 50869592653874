export const EndTitle = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 402 472"
            >
                <defs>
                    <style>
                        {`.titleclass-1{fill:#fbe21c;}
                  .titleclass-2{fill:#e40046;}
                  .titleclass-3{fill:#4d2370;}
                  .titleclass-4{isolation:isolate;}`}
                    </style>
                </defs>
                <g className="titleclass-4">
                    <path
                        className="titleclass-3"
                        d="m26.86,315.56h-6.54v-19.64h-6.55v-6.54h-6.54v-6.54H.68v-32.73h13.09v26.18h6.55v6.54h13.09v-6.54h6.54v-26.18h13.09v32.73h-6.54v6.54h-6.55v6.54h-6.54v19.64h-6.55Z"
                    />
                    <path
                        className="titleclass-3"
                        d="m98.85,315.56h-26.18v-6.55h-6.54v-6.54h-6.55v-39.27h6.55v-6.55h6.54v-6.55h32.73v6.55h6.54v6.55h6.55v39.27h-6.55v6.54h-6.54v6.55h-6.55Zm-6.54-13.09h6.54v-6.55h6.55v-26.18h-6.55v-6.54h-19.64v6.54h-6.55v26.18h6.55v6.55h13.09Z"
                    />
                    <path
                        className="titleclass-3"
                        d="m157.76,315.56h-19.64v-6.55h-6.54v-6.54h-6.55v-52.36h13.09v45.82h6.55v6.55h13.09v-6.55h6.54v-45.82h13.09v52.36h-6.54v6.54h-6.55v6.55h-6.54Z"
                    />
                    <path
                        className="titleclass-3"
                        d="m275.57,315.56h-13.09v-6.55h-13.09v6.55h-19.64v-6.55h-6.54v-6.54h-6.54v-52.36h13.09v45.82h6.54v6.55h6.54v-6.55h6.55v-45.82h13.09v45.82h6.54v6.55h6.54v-6.55h6.54v-45.82h13.09v52.36h-6.55v6.54h-6.54v6.55h-6.54Z"
                    />
                    <path
                        className="titleclass-3"
                        d="m308.3,315.56h-6.55v-65.45h13.09v65.45h-6.54Z"
                    />
                    <path
                        className="titleclass-3"
                        d="m367.21,315.56h-6.54v-45.82h-6.54v-6.54h-13.09v6.54h-6.55v45.82h-13.09v-52.36h6.54v-6.55h6.54v-6.55h26.18v6.55h6.54v6.55h6.54v52.36h-6.54Z"
                    />
                    <path
                        className="titleclass-3"
                        d="m386.84,295.92h-6.55v-45.82h13.09v45.82h-6.54Zm0,19.64h-6.55v-13.09h13.09v13.09h-6.54Z"
                    />
                </g>
                <image
                    className="titleclass-4"
                    width="17"
                    height="17"
                    transform="translate(288 65)"
                    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAACXBIWXMAAAsSAAALEgHS3X78AAAAHklEQVQ4jWN8wuD2n4FCwESpAaOGjBoyasioIVAAADM7AktZR6biAAAAAElFTkSuQmCC"
                />
                <image
                    className="titleclass-4"
                    width="33"
                    height="33"
                    transform="translate(211 374)"
                    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAYAAABX5MJvAAAACXBIWXMAAAsSAAALEgHS3X78AAAATUlEQVRYhe3OMQ2AABDAwEIYSSDBE/4lMCCDgakafukpuOXlfoCDQRtwAftkYgW+yYCJcSVUQiVUQiVUQiVUQiVUQiVUQiVUQitwTid+PckDiH71Zj4AAAAASUVORK5CYII="
                />
                <image
                    className="titleclass-4"
                    width="19"
                    height="19"
                    transform="translate(68)"
                    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABMAAAATCAYAAAByUDbMAAAACXBIWXMAAAsSAAALEgHS3X78AAAATklEQVQ4je3MsQmAMAAAsFg6uBTEoeD/nwh+0DsEcVTEyQe0m80B6dZtL8i4vNdjiZgwfogeOeCoEMEZKkWgZS1r2U+yodKVImakClm5ASMdCakykf8eAAAAAElFTkSuQmCC"
                />
                <image
                    className="titleclass-4"
                    width="28"
                    height="28"
                    transform="translate(307 427)"
                    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAYAAAByDd+UAAAACXBIWXMAAAsSAAALEgHS3X78AAAAiElEQVRIie3WwQnCQBBA0ZfNiihahfXYi5UKnm1CkCASkvUQkRSQ7MX552UeO6dpzqfLEzsUDJatoMUGHa4Zh9mDvDA4b4t9wrgiMm+EhL4SOKBPlbBfAQYYYIABBhjgv4ClklVQkulurFGLnExHao22OGa80Fh3tQlvPDJupu+OK6HNd36H+wdVKReX/bQJOgAAAABJRU5ErkJggg=="
                />
                <image
                    className="titleclass-4"
                    width="8"
                    height="8"
                    transform="translate(52 464)"
                    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAACXBIWXMAAAsSAAALEgHS3X78AAAASElEQVQYla3KOw5AQABAwVlZhYQtFO4fvVM4hjtIRCk+NWErr50X5mWd0OJ0L2CI6JC8lwpsHwh7kUHwz1BnvIro0eB4YInxAjIRCjQzK0/mAAAAAElFTkSuQmCC"
                />
                <image
                    className="titleclass-4"
                    width="11"
                    height="11"
                    transform="translate(150 111)"
                    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAACXBIWXMAAAsSAAALEgHS3X78AAAAMUlEQVQYlWP8/UjmBQMDAzcDEYCFgYGBj4GBgZMYxUwMDAx/iVEIU0w0GBGKGWliMgBu+wQZDSXa/AAAAABJRU5ErkJggg=="
                />
                <image
                    className="titleclass-4"
                    width="3"
                    height="3"
                    transform="translate(244 199)"
                    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAADCAYAAABWKLW/AAAACXBIWXMAAAsSAAALEgHS3X78AAAAJ0lEQVQImSXBsREAEAAEsLizlOHMZh0dFvAKSUnS0XAkGfluxcTCfpHWEyS/i47XAAAAAElFTkSuQmCC"
                />
                <image
                    className="titleclass-4"
                    width="8"
                    height="8"
                    transform="translate(304 339)"
                    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAACXBIWXMAAAsSAAALEgHS3X78AAAAb0lEQVQYlW3MsQrCQBAA0XfJETEmhBR2wUZ/058VLCxsLIR4iDYbiODCwLIzbCqX6YwtDujRocENJYdsQ3Sr4LkER+xwCtmixh7vjDGCMWSDhA0+OWSPIY4/UwX1PwkZVzzi0xAk3DFXKHhhXmHZvzwoFJzG3aHzAAAAAElFTkSuQmCC"
                />
                <image
                    className="titleclass-4"
                    width="8"
                    height="8"
                    transform="translate(104 417)"
                    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAACXBIWXMAAAsSAAALEgHS3X78AAAAcElEQVQYlW3MwQqCUBBA0fP0oWSKuHDrpr6lv+pva9GiTYvAJGozgkEDF4a5l0kXpzN2mNChRYUblhyyCdFugucaHLDHMWSDEiPeGUMEQ8gKCTU+OWSHPo4/UwTlPwkZVzziUx8k3DEXWPDCvMG6fwFn/hPN0LuRVgAAAABJRU5ErkJggg=="
                />
                <g className="titleclass-4">
                    <path
                        className="titleclass-1"
                        d="m34.86,315.56h-6.54v-19.64h-6.55v-6.54h-6.54v-6.54h-6.55v-32.73h13.09v26.18h6.55v6.54h13.09v-6.54h6.54v-26.18h13.09v32.73h-6.54v6.54h-6.55v6.54h-6.54v19.64h-6.55Z"
                    />
                    <path
                        className="titleclass-1"
                        d="m106.85,315.56h-26.18v-6.55h-6.54v-6.54h-6.55v-39.27h6.55v-6.55h6.54v-6.55h32.73v6.55h6.54v6.55h6.55v39.27h-6.55v6.54h-6.54v6.55h-6.55Zm-6.54-13.09h6.54v-6.55h6.55v-26.18h-6.55v-6.54h-19.64v6.54h-6.55v26.18h6.55v6.55h13.09Z"
                    />
                    <path
                        className="titleclass-1"
                        d="m165.76,315.56h-19.64v-6.55h-6.54v-6.54h-6.55v-52.36h13.09v45.82h6.55v6.55h13.09v-6.55h6.54v-45.82h13.09v52.36h-6.54v6.54h-6.55v6.55h-6.54Z"
                    />
                    <path
                        className="titleclass-1"
                        d="m283.57,315.56h-13.09v-6.55h-13.09v6.55h-19.64v-6.55h-6.54v-6.54h-6.54v-52.36h13.09v45.82h6.54v6.55h6.54v-6.55h6.55v-45.82h13.09v45.82h6.54v6.55h6.54v-6.55h6.54v-45.82h13.09v52.36h-6.55v6.54h-6.54v6.55h-6.54Z"
                    />
                    <path
                        className="titleclass-1"
                        d="m316.3,315.56h-6.55v-65.45h13.09v65.45h-6.54Z"
                    />
                    <path
                        className="titleclass-1"
                        d="m375.21,315.56h-6.54v-45.82h-6.54v-6.54h-13.09v6.54h-6.55v45.82h-13.09v-52.36h6.54v-6.55h6.54v-6.55h26.18v6.55h6.54v6.55h6.54v52.36h-6.54Z"
                    />
                    <path
                        className="titleclass-1"
                        d="m394.84,295.92h-6.55v-45.82h13.09v45.82h-6.54Zm0,19.64h-6.55v-13.09h13.09v13.09h-6.54Z"
                    />
                </g>
                <g className="titleclass-4">
                    <path
                        className="titleclass-2"
                        d="m27.86,309.56h-6.54v-19.64h-6.55v-6.54h-6.54v-6.54H1.68v-32.73h13.09v26.18h6.55v6.54h13.09v-6.54h6.54v-26.18h13.09v32.73h-6.54v6.54h-6.55v6.54h-6.54v19.64h-6.55Z"
                    />
                    <path
                        className="titleclass-2"
                        d="m99.85,309.56h-26.18v-6.55h-6.54v-6.54h-6.55v-39.27h6.55v-6.55h6.54v-6.55h32.73v6.55h6.54v6.55h6.55v39.27h-6.55v6.54h-6.54v6.55h-6.55Zm-6.54-13.09h6.54v-6.55h6.55v-26.18h-6.55v-6.54h-19.64v6.54h-6.55v26.18h6.55v6.55h13.09Z"
                    />
                    <path
                        className="titleclass-2"
                        d="m158.76,309.56h-19.64v-6.55h-6.54v-6.54h-6.55v-52.36h13.09v45.82h6.55v6.55h13.09v-6.55h6.54v-45.82h13.09v52.36h-6.54v6.54h-6.55v6.55h-6.54Z"
                    />
                    <path
                        className="titleclass-2"
                        d="m276.57,309.56h-13.09v-6.55h-13.09v6.55h-19.64v-6.55h-6.54v-6.54h-6.54v-52.36h13.09v45.82h6.54v6.55h6.54v-6.55h6.55v-45.82h13.09v45.82h6.54v6.55h6.54v-6.55h6.54v-45.82h13.09v52.36h-6.55v6.54h-6.54v6.55h-6.54Z"
                    />
                    <path
                        className="titleclass-2"
                        d="m309.3,309.56h-6.55v-65.45h13.09v65.45h-6.54Z"
                    />
                    <path
                        className="titleclass-2"
                        d="m368.21,309.56h-6.54v-45.82h-6.54v-6.54h-13.09v6.54h-6.55v45.82h-13.09v-52.36h6.54v-6.55h6.54v-6.55h26.18v6.55h6.54v6.55h6.54v52.36h-6.54Z"
                    />
                    <path
                        className="titleclass-2"
                        d="m387.84,289.92h-6.55v-45.82h13.09v45.82h-6.54Zm0,19.64h-6.55v-13.09h13.09v13.09h-6.54Z"
                    />
                </g>
            </svg>
        </>
    );
};
