import { useAppSelector } from '../../hooks';
import { LockedHint } from './LockedHint';
import { UnlockedHint } from './UnlockedHint';

export const Hint = ({ id, text }: { id: number; text: JSX.Element }) => {
    const unlockedHint = useAppSelector((state) => state.appState.unlockedHint);
    const isUnlocked = unlockedHint < id;
    if (isUnlocked) {
        return (
            <>
                <LockedHint id={id} />
            </>
        );
    }
    return <UnlockedHint text={text} id={id} />;
};
