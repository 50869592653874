import { useEffect } from 'react';
import { T_Slot } from '../../../types';
import { useAppDispatch } from '../../hooks';
import { declarePreVictory, declareVictory } from '../../store/appState';
import { Slot } from './Slot';
import { SlotFrontPart } from './SlotFrontPart';
import { SlotSidePart } from './SlotSidePart';
import { SlotUpperPart } from './SlotUpperPart';
import { SlotsWrapper } from './SlotsWrapper';

export const Slots = ({ slots }: { slots: T_Slot[] }) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (
            slots[0].insertedKey === 6 &&
            slots[1].insertedKey === 1 &&
            slots[2].insertedKey === 5 &&
            slots[3].insertedKey === 3
        ) {
            dispatch(declarePreVictory());
            const timer = setTimeout(() => {
                dispatch(declareVictory());
            }, 3000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, []);
    return (
        <SlotsWrapper>
            <SlotFrontPart />
            <SlotUpperPart />
            <SlotSidePart left />
            <SlotSidePart full />
            <SlotSidePart />
            {slots.map((slot: T_Slot) => {
                return <Slot key={slot.id} slot={slot} />;
            })}
        </SlotsWrapper>
    );
};
