import { useEffect, useState } from 'react';

export const useTimedEffect = (duration: number) => {
    const [timeExpired, setTimeExpired] = useState<boolean>(false);
    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;

        const startCountdown = () => {
            timer = setTimeout(() => {
                setTimeExpired(true);
            }, duration);
        };

        startCountdown();

        return (): void => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [duration]);
    return timeExpired;
};
