export const formatTime = (seconds: number): string => {
    const formattedSeconds = Math.round(Number(Math.abs(seconds)));
    const minutes = Math.floor(formattedSeconds / 60);
    const remainingSeconds = formattedSeconds % 60;
    const displayMinutes = String(minutes).padStart(2, '0');
    let displaySeconds: string | number = Math.round(
        Number(Math.abs(Number(String(remainingSeconds).padStart(2, '0')))),
    );
    if (displaySeconds < 10) {
        displaySeconds = '0' + displaySeconds;
    }
    if (Number(displaySeconds) > 59 || Number(displaySeconds) < 0) {
        displaySeconds = 59;
    }
    return `${
        Math.sign(seconds) === -1 ? '-' : ''
    }${displayMinutes}:${displaySeconds}`;
};
