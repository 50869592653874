import { useState } from 'react';
import { FifteenMinutes } from '../components/FifteenMinutes';
import { Logo } from '../components/Logo';
import { SoundIcon } from '../components/SoundIcon';
import { useAppDispatch } from '../hooks';
import { useMediaDimensions } from '../hooks/useMediaDimensions';
import { handleMute, setPage } from '../store/appState';
import { StartWrapper } from './IntroWrapper';

export const Start = () => {
    const dispatch = useAppDispatch();
    const handleButtonClick = () => {
        dispatch(setPage('Intro'));
    };
    const [buttonPressed, setButtonPressed] = useState<boolean>(false);
    const handleStartClick = () => {
        if (buttonPressed) return;
        dispatch(setPage('InVideo'));
        setButtonPressed(true);
    };
    const handleSoundClick = () => {
        dispatch(handleMute());
    };
    const { width, height } = useMediaDimensions();

    return (
        <>
            <StartWrapper>
                <div className="arcade-logo-wrapper">
                    <Logo />
                </div>
                <div className="buttons-wrapper">
                    <div
                        className="start-button-wrapper"
                        onClick={handleStartClick}
                    >
                        <div className="fifteen-minutes-wrapper">
                            <FifteenMinutes />
                        </div>
                        <p
                            style={{
                                fontSize: '75px',
                                color: '#fff353',
                                fontFamily: 'Bauhaus',
                            }}
                        >
                            START
                        </p>
                    </div>
                </div>
                <div
                    className="sound-icon-wrapper"
                    onClick={() => handleSoundClick()}
                >
                    <SoundIcon />
                </div>
            </StartWrapper>
        </>
    );
};
