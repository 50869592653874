import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './hooks';
import { RootState } from './store';
import { decreaseTimer } from './store/appState';
import { activateChrono } from './store/chronoState/slice';

export const Timer = (props: any) => {
    const time = useAppSelector((state: RootState) => state.appState.timer);
    const dispatch = useAppDispatch();
    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(decreaseTimer());
            dispatch(activateChrono());
        }, 250);
        return () => {
            clearTimeout(timer);
        };
    }, [time, dispatch]);
    return null;
};
