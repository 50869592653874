import { useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks';
import { resetLetris, setLetrisDrag } from '../store/appState';
import { Border } from './Letris/Border';
import { LetrisBlock } from './Letris/LetrisBlock';
export const Letris = () => {
    const dispatch = useAppDispatch();
    const letrisRef = useRef<HTMLDivElement>(null);
    const letterBlocks = useAppSelector((state) => state.appState.letrisBlocks);
    const containerRef = useRef<HTMLImageElement>(null);
    const borderRef = useRef<HTMLDivElement>(null);
    const [loaded, setLoaded] = useState<boolean>(false);
    const border = borderRef.current?.getBoundingClientRect().left;
    const letrisReset = useAppSelector((state) => state.appState.letrisReset);
    return (
        <>
            <div className="screen-letris">
                {/* <span id="letris-instructions" className="screen-instructions">
                    Sleep de blokken op de juiste plaats
                </span> */}
                <div id="refresh" className="refresh">
                    <span onClick={() => dispatch(resetLetris())}>
                        ↻ Opnieuw
                    </span>
                </div>
                <div
                    id="letris-letters"
                    className="screen-letris-bg"
                    ref={letrisRef}
                    onMouseUp={() => dispatch(setLetrisDrag(null))}
                >
                    <img
                        onLoad={() => setLoaded(true)}
                        ref={containerRef}
                        draggable={false}
                        src="/images/letris_bg.png"
                    />
                    {letrisReset || (
                        <>
                            <Border borderRef={borderRef} />
                            {letterBlocks.map((block, index) => (
                                <LetrisBlock
                                    border={border}
                                    letrisRef={letrisRef}
                                    key={index}
                                    id={block.id}
                                />
                            ))}{' '}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
