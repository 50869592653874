import React, { useContext, useRef, useState } from 'react';
import { T_PlacmanDraw, T_Screen } from '../../../types';
import { useAppDispatch } from '../../hooks';
import { addPlacManDraw, setPlacManLoaded } from '../../store/appState';

// enabling drawing on the blank canvas
const CanvasContext = React.createContext(null) as any;

export const CanvasProvider = ({ children }: any) => {
    const [isDrawing, setIsDrawing] = useState(false);
    const canvasRef = useRef(null) as any;
    const contextRef = useRef(null) as any;
    const dispatch = useAppDispatch();

    //defining width & height of the canvas
    const prepareCanvas = (screen: T_Screen) => {
        const canvas = canvasRef.current;
        canvas.width = window.innerWidth * 2;
        canvas.height = window.innerWidth * 2;
        canvas.style.width = `${window.innerWidth}px`;
        canvas.style.height = `${window.innerWidth}px`;

        const context = canvas.getContext('2d');
        context.scale(2, 2);
        context.lineCap = 'round';
        context.strokeStyle = 'blue';
        context.lineWidth = 3;
        contextRef.current = context;
    };

    const retrieveDrawing = (drawings: T_PlacmanDraw[]) => {
        drawings.map((drawing, index) => {
            if (drawing.start) {
                contextRef.current.beginPath();
                contextRef.current.moveTo(drawing.x, drawing.y);
            }
            if (!drawing.start) {
                contextRef.current.lineTo(drawing.x, drawing.y);
                contextRef.current.stroke();
            }
            if (drawings[index + 1] && drawings[index + 1].start) {
                contextRef.current.closePath();
            }
        });
    };

    const startDrawing = ({ nativeEvent }: any) => {
        const { screenX, screenY } = nativeEvent;
        let xValue = screenX;
        let yValue = screenY;
        if (window.innerWidth > 600 && nativeEvent.pageX && nativeEvent.pageY) {
            xValue = (nativeEvent.pageX - window.innerWidth / 2 + 250) / 2;
            yValue =
                (nativeEvent.pageY - window.innerHeight / 2 + 250) / 2 + 30;
        }
        contextRef.current.beginPath();
        contextRef.current.moveTo(xValue, yValue);
        dispatch(addPlacManDraw({ x: xValue, y: yValue, start: true }));
        setIsDrawing(true);
    };

    const finishDrawing = () => {
        contextRef.current.closePath();
        setIsDrawing(false);
    };

    const draw = ({ nativeEvent }: { nativeEvent: any }) => {
        let xValue;
        let yValue;
        if (!isDrawing) {
            return;
        }
        if (nativeEvent.targetTouches && nativeEvent.targetTouches[0]) {
            const { pageX, pageY } = nativeEvent.targetTouches[0];
            xValue = pageX;
            yValue = pageY + 70;
        }

        if (nativeEvent.pageX && nativeEvent.pageY && window.innerWidth > 600) {
            xValue = (nativeEvent.pageX - window.innerWidth / 2 + 250) / 2;
            yValue =
                (nativeEvent.pageY - window.innerHeight / 2 + 250) / 2 + 30;
        }
        dispatch(addPlacManDraw({ x: xValue, y: yValue }));
        contextRef.current.lineTo(xValue, yValue);
        contextRef.current.stroke();
    };

    const exportCanvas = async ({ time }: { time: string }) => {
        try {
            const getBase64StringFromDataURL = (dataURL: any) =>
                dataURL.replace('data:', '').replace(/^.+,/, '');
            const b64toBlob = (
                b64Data: any,
                contentType = '',
                sliceSize = 512,
            ) => {
                const byteCharacters = atob(b64Data);
                const byteArrays = [];
                for (
                    let offset = 0;
                    offset < byteCharacters.length;
                    offset += sliceSize
                ) {
                    const slice = byteCharacters.slice(
                        offset,
                        offset + sliceSize,
                    );

                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    byteArrays.push(byteArray);
                }

                const blob = new Blob(byteArrays, { type: contentType });
                return blob;
            };
            const canvas = canvasRef.current;
            const dataURL = canvas.toDataURL('image/png', 0.5);
            const base64 = getBase64StringFromDataURL(dataURL);
            const blob = b64toBlob(base64);
            const file = new File([blob], 'share.png', { type: 'image/png' });
            navigator.share({
                title: 'Escape Room The Game Arcade, kan jij ontsnappen?',
                text: `Kan jij sneller ontsnappen dan ${time ? time : 'ik'}?`,
                url: 'https://arcade.escaperoomthegame.com/',
                files: [file],
            });
        } catch (error) {
            console.log(error);
        }
    };

    //once the canvas is cleared return to the default colour
    const clearCanvas = async (image: string) => {
        dispatch(setPlacManLoaded(false));
        const canvas = canvasRef.current;
        let canvasWidth = window.innerWidth * 2;
        let canvasHeight = window.innerHeight * 2;
        if (window.innerWidth > 600) {
            canvasWidth = 500;
            canvasHeight = 500;
        }
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        canvas.style.width = `${
            window.innerWidth > 600 ? '500' : window.innerWidth
        }px`;
        canvas.style.height = `${
            window.innerWidth > 600 ? '500' : window.innerHeight - 7
        }px`;

        const context = canvas.getContext('2d');
        context.scale(2, 2);
        context.lineCap = 'round';
        context.strokeStyle = 'blue';
        context.lineWidth = 5;
        contextRef.current = context;
        const img = new Image();
        if (!img) return;
        img.onload = () => {
            dispatch(setPlacManLoaded(true));
            context.drawImage(
                img,
                0,
                window.innerWidth > 600 ? 0 : window.innerWidth / 2,
                window.innerWidth > 600 ? 250 : window.innerWidth,
                window.innerWidth > 600 ? 250 : window.innerWidth,
            );
            contextRef.current = context;
        };
        img.src = image;
    };

    const drawEndCanvas = async (image: string) => {
        //HIER VERDER
        dispatch(setPlacManLoaded(false));
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        context.canvas.height = 1900;
        context.canvas.width = 600;
        let canvasWidth = window.innerWidth * 2;
        let canvasHeight = window.innerHeight * 1.8;
        if (window.innerWidth > 600) {
            canvasWidth = 500;
            canvasHeight = 500;
        }
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        canvas.style.width = `${
            window.innerWidth > 600 ? '520' : window.innerWidth - 100
        }px`;
        const scale = window.innerWidth > 600 ? 1 : 1.5;
        canvas.style.height = `${
            window.innerWidth > 600 ? '848' : 448 * scale
        }px`;

        context.scale(2, 2);
        context.imageSmoothingEnabled = false;
        contextRef.current = context;
        const img = new Image();
        if (!img) return;
        img.onload = () => {
            dispatch(setPlacManLoaded(true));
            context.drawImage(
                img,
                0,
                0,
                window.innerWidth > 600 ? 250 : window.innerWidth,
                window.innerWidth > 600 ? 250 : window.innerHeight - 92,
            );
            contextRef.current = context;
        };
        img.src = image;
    };

    const drawTime = async (time: number) => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const textX = window.innerWidth > 600 ? 90 : window.innerWidth / 2 - 72;
        const textY =
            window.innerWidth > 600 ? 120 : window.innerHeight / 2 - 70;
        context.font =
            window.innerWidth > 600
                ? '32px Clock, sans-serif'
                : '64px Clock, sans-serif';
        context.fillStyle = '#A1FF9F';
        context.fillText(`${time}`, textX, textY);
    };

    return (
        <CanvasContext.Provider
            value={{
                canvasRef,
                contextRef,
                prepareCanvas,
                startDrawing,
                finishDrawing,
                drawEndCanvas,
                clearCanvas,
                draw,
                retrieveDrawing,
                exportCanvas,
                drawTime,
            }}
        >
            {children}
        </CanvasContext.Provider>
    );
};

export const useCanvas = () => useContext(CanvasContext);
