import { T_LetrisLetters } from '../../types';

const width = ['', '10.2%', '20.4%', '30.6%'];
const smallWidth = ['', '5.2%', '10.4%', '15.6%'];

export const getWidthFromBlock = ({
    blockId,
    enlarged,
}: {
    blockId: T_LetrisLetters;
    enlarged: boolean;
}) => {
    let returnWidth = width;
    if (!enlarged) {
        returnWidth = smallWidth;
    }
    switch (blockId) {
        case 'ANOO':
            return returnWidth[3];
        case 'FTTW':
            return returnWidth[1];
        case 'HJES':
            return returnWidth[2];
        case 'JUIE':
            return returnWidth[3];
        case 'VIIS':
            return returnWidth[3];
        default:
            return returnWidth[3];
    }
};
