import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { setDragScroll, setScroll } from '../store/appState';

export const Tunnel = () => {
    const tunnelRef = useRef<any>(null);
    const scrollRef = useRef<any>(null);
    const [mouseScroll, setMouseScroll] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const scroll = useSelector((state: any) => state.appState.scroll);
    useEffect(() => {
        if (!tunnelRef || !tunnelRef.current || !scroll) return;
        scrollRef.current.scrollTop = scroll.height;
        scrollRef.current.scrollLeft = scroll.width;
    }, [tunnelRef, scroll, mouseScroll]);

    return (
        <div
            id="tunnel"
            className="tunnel"
            onScroll={(e: any) => {
                const scroll = {
                    height: scrollRef.current.scrollTop,
                    width: scrollRef.current.scrollLeft,
                };
                dispatch(setScroll(scroll));
            }}
            onMouseDown={(e) => {
                setMouseScroll(true);
            }}
            onMouseUp={(e) => {
                setMouseScroll(false);
            }}
            onMouseMove={(e) => {
                if (!mouseScroll || isNaN(e.movementX) || isNaN(e.movementY))
                    return;
                dispatch(setDragScroll({ x: e.movementX, y: e.movementY }));
            }}
            ref={scrollRef}
        >
            <picture style={{ opacity: 1 }}>
                <source srcSet="images/tunnel.webp" type="image/webp" />
                <source srcSet="images/tunnel.jpg" type="image/jpg" />
                <img
                    src="images/tunnel.jpg"
                    width="2480"
                    height="2644"
                    loading="lazy"
                    alt="Kelder"
                    ref={tunnelRef}
                />
            </picture>
        </div>
    );
};
