import { T_Slot } from '../../../types';
import { SlotEntry } from './SlotEntry';
import { SlotHitbox } from './SlotHitbox';

export const Slot = ({ slot }: { slot: T_Slot }) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                width: '100%',
                marginLeft: '5px',
                marginRight: '5px',
                zIndex: 10,
            }}
        >
            <SlotHitbox slot={slot} />
            <SlotEntry slot={slot} />
        </div>
    );
};
