import { useAppSelector } from '../../hooks';

export const SlotSidePart = ({
    left,
    full,
}: {
    left?: boolean;
    full?: boolean;
}) => {
    const keyDimensions = useAppSelector(
        (state) => state.chronoState?.keyDimensions,
    );
    const keyHeight = useAppSelector(
        (state) => state.chronoState?.keyDimensions?.height,
    );
    const container = useAppSelector(
        (state) => state.chronoState?.containerHitbox,
    );
    if (!keyDimensions || !container) return null;
    const width = !full
        ? (container?.xRight -
              container?.xLeft -
              (keyDimensions?.width + 10) * 4) /
          2
        : container?.xRight - container?.xLeft;
    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    bottom: keyHeight && keyHeight * 0.52,
                    left: left || full ? 0 : undefined,
                    right: !left ? 0 : undefined,
                    height: keyHeight && keyHeight * 0.06,
                    width: width,
                    backgroundColor: '#2f2e2d',
                    zIndex: 0,
                }}
            />
        </>
    );
};
