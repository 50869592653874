import { HintWrapper } from './HintWrapper';

export const UnlockedHint = (props: { id: number; text: JSX.Element }) => {
    const { id, text } = props;
    return (
        <HintWrapper id={id}>
            <p
                style={{
                    whiteSpace: 'pre-wrap',
                    overflowX: 'revert',
                    paddingTop: '2vh',
                    paddingBottom: '2vh',
                    paddingLeft: '2vw',
                    paddingRight: '2vw',
                }}
            >
                {text}
            </p>
        </HintWrapper>
    );
};
