import { T_Page } from '../types';
import { Timer } from './Timer';
import { InVideo } from './components/InVideo';
import { Header } from './header/Header';
import { usePage } from './hooks/usePage';
import { End } from './pages/End';
import { EndShare } from './pages/EndShare';
import { InGame } from './pages/InGame';
import { InScreen } from './pages/InScreen';
import { Intro } from './pages/Intro';
import { Start } from './pages/Start';

export const Pages = () => {
    const page: T_Page = usePage();
    switch (page) {
        case 'InVideo':
            return <InVideo />;
        case 'Start':
            return <Start />;
        case 'Intro':
            return <Intro />;
        case 'InGame':
            return (
                <>
                    <Timer />
                    <InGame />
                    <Header />
                </>
            );
        case 'InScreen':
            return (
                <>
                    <Timer />
                    <InScreen />
                    <Header />
                </>
            );
        case 'End':
            return (
                <>
                    <End />
                </>
            );
        case 'EndShare':
            return (
                <>
                    <EndShare />
                </>
            );
        default:
            return <div>404</div>;
    }
};
