import React, { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { setKeyDimensions } from '../../store/chronoState/slice';

export const SlotsWrapper = ({ children }: { children: React.ReactNode }) => {
    const ref = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const keyDimensions = useAppSelector(
        (state) => state.chronoState?.keyDimensions,
    );
    const keysArranged = useAppSelector(
        (state) => state.chronoState.keysArranged,
    );
    const windowDimensions = useWindowDimensions();
    const slotCount = 4;
    useEffect(() => {
        if (!ref || !ref.current) return;
        const { width, height } = ref.current.getBoundingClientRect();

        if (!keyDimensions || !keysArranged) {
            dispatch(
                setKeyDimensions({
                    width: width / slotCount - 10,
                    height,
                    slotCount,
                }),
            );
        }
    }, [ref.current, windowDimensions, keysArranged]);

    // useEffect(() => {
    //     if (!ref || !ref.current) return;
    //     const { width, height } = ref.current.getBoundingClientRect();
    //     dispatch(setKeyDimensions({ width: width / slotCount - 10, height }));
    // }, [ref.current]);

    return (
        <>
            <div
                style={{
                    justifyContent: 'center',
                    justifySelf: 'center',
                    alignSelf: 'end',
                    display: 'flex',
                    width: '100%',
                    height: '200px',
                }}
            >
                <div
                    ref={ref}
                    style={{
                        justifyContent: 'center',
                        justifySelf: 'center',
                        alignSelf: 'end',
                        display: 'flex',
                    }}
                >
                    {children}
                </div>
            </div>
        </>
    );
};
