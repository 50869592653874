import { T_Slot } from '../../types';

export const findSlotUnderKey = ({
    slots,
    x,
    y,
}: {
    slots: T_Slot[];
    x: number;
    y: number;
}) => {
    return slots.find((slot) => {
        if (slot.hitbox.xLeft < x && slot.hitbox.xRight > x) {
            if (slot.hitbox.yTop < y && slot.hitbox.yBottom > y) {
                return true;
            }
        }
    });
};
