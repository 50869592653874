import React from 'react';

export const Container = ({
    children,
    reference,
}: {
    children: React.ReactNode;
    reference: React.RefObject<HTMLDivElement>;
}) => {
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100vw',
                height: 'calc(100vh - 102px)',
                backgroundImage: 'url(/images/chrono_bg.jpg)',
                backgroundSize: 'cover',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    position: 'relative',
                    height: '100%',
                    width: '80%',
                }}
                ref={reference}
            >
                {children}
            </div>{' '}
        </div>
    );
};
