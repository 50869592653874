import { useRef } from 'react';
import { muteSound } from '../functions/muteSound';
import { playSound } from '../functions/playSound';
import { useAppDispatch, useAppSelector, usePage } from '../hooks';
import { T_SoundKey, sounds } from '../sounds';
import { setPage, setRoom, setScreen } from '../store/appState';
import { StartWrapper } from './IntroWrapper';

export const Intro = () => {
    const page = usePage();
    const dispatch = useAppDispatch();
    const muteState = useAppSelector(
        (state) => state.appState.soundSettings.muted,
    );
    const timer = useAppSelector((state) => state.appState.timer);
    const ref = useRef<HTMLDivElement>(null);

    const handleClick = () => {
        if (timer >= 900) {
            if (!muteState) {
                muteSound();
                playSound(sounds.demoAmbient as T_SoundKey);
            }
        }
        dispatch(setRoom('Arcade'));
        dispatch(setScreen(null));
        dispatch(setPage('InGame'));
    };

    return (
        <StartWrapper>
            {' '}
            <div className="explain-wrapper" ref={ref}>
                <span className="explanation-arrow-down">↓</span>
                <div className="explain-text">
                    <div>
                        <h1 className="explain-heading"> Speluitleg </h1>
                        <p className="explain-section">
                            Je hebt <b>15 minuten</b> om een viercijferige code
                            te vinden.
                        </p>
                        <p className="explain-section">
                            <b>Hoe doe je dat?</b>
                        </p>
                        <p className="explain-section inline">
                            &#x2022; Bekijk de puzzels in de arcadehal en in de
                            kelder goed en zoek naar aanwijzingen om de puzzels
                            op te lossen.{' '}
                        </p>
                        <p className="explain-section inline">
                            &#x2022; Uit elk van de <b>vier arcadespellen</b>{' '}
                            komt een cijfer van 1 tot 6 als oplossing.{' '}
                        </p>
                        <p className="explain-section inline">
                            &#x2022; Deze cijfers vind je ook op de sleutels van
                            de chronodecoder. Stop de sleutels in de
                            chronodecoder en check de code.{' '}
                        </p>
                        <p className="explain-section">
                            <b>Verkeerde code?</b>
                        </p>
                        <p className="explain-section inline">
                            {' '}
                            &#x2022; Is de viercijferige code onjuist, dan hoor
                            je een ‘fout’-signaal en gaat er 1 minuut van de
                            tijd af.
                        </p>
                        <p className="explain-section inline">
                            {' '}
                            &#x2022; Probeer het vervolgens opnieuw. Loop je
                            vast? Tijdens de 15 minuten speeltijd krijg je 3
                            hints, elke 5 minuten een hint. Deze hints kunnen je
                            helpen.{' '}
                        </p>
                        <p className="explain-section">
                            <b>Maak kans op prijzen</b>
                        </p>
                        <p className="explain-section inline">
                            &#x2022; Speel de vier arcadespellen uit, vind de
                            juiste code en maak kans op mooie prijzen!
                        </p>{' '}
                        <p className="explain-section inline">
                            &#x2022; Je krijgt alvast een hint: volg de
                            elektriciteitskabels vanaf speelkast ‘Magic Portal’
                            in de arcadehal en de kelder voor de juiste
                            volgorde.
                        </p>
                        <b> Veel succes!</b>
                    </div>
                </div>{' '}
            </div>{' '}
            <div className="explanation-back-wrapper">
                <label onClick={() => handleClick()} className="explain-back">
                    <u className="text-none">
                        {timer >= 900 ? 'Start' : 'Terug'}
                    </u>
                </label>
            </div>
        </StartWrapper>
    );
};
