import { useScreen } from '../hooks/useScreen';
import { CanvasProvider } from './Plac/CanvasContext';
import { PlacCanvas } from './Plac/PlacCanvas';

export const Plac = () => {
    const screen = useScreen();

    if (screen !== 'PLAC') return null;
    return (
        <CanvasProvider>
            <PlacCanvas />
        </CanvasProvider>
    );
};
