import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { T_ChronoState, T_Hitbox } from '../../../types/';
import { findSlotUnderKey } from '../../functions/findSlotUnderKey';

const initialHitbox = {
    xLeft: 0,
    yTop: 0,
    xRight: 0,
    yBottom: 0,
};

const initialState: T_ChronoState = {
    keysArranged: false,
    containerHitbox: initialHitbox,
    keyDimensions: null,
    keys: [1, 2, 3, 4, 5, 6].map((id: number) => {
        return {
            id,
            image: `/images/Sleutel_${id}.png`,
            position: {
                x: 0,
                y: 0,
            },
            startPosition: {
                x: 0,
                y: 0,
            },
        };
    }),
    slots: [
        {
            id: 0,
            insertedKey: null,
            hitbox: initialHitbox,
            keyAboveSlot: false,
        },
        {
            id: 1,
            insertedKey: null,
            hitbox: initialHitbox,
            keyAboveSlot: false,
        },
        {
            id: 2,
            insertedKey: null,
            hitbox: initialHitbox,
            keyAboveSlot: false,
        },
        {
            id: 3,
            insertedKey: null,
            hitbox: initialHitbox,
            keyAboveSlot: false,
        },
    ],
    chronoReset: false,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        activateChrono: (state) => {
            state.chronoReset = false;
        },
        resetChrono: (state) => {
            state.chronoReset = true;
            state.keysArranged = false;
            state.slots.map((slot) => {
                slot.insertedKey = null;
                return slot;
            });
        },
        setKeyDimensions: (
            state,
            action: PayloadAction<{
                width: number;
                height: number;
                slotCount: number;
            }>,
        ) => {
            const { width, height, slotCount } = action.payload;
            const keyMargin = 100;
            state.keyDimensions = { width, height };

            if (!state.keysArranged) {
                let rows = 1;
                if (6 * action.payload.width > window.innerWidth * 0.75) {
                    rows = 2;
                }
                state.keys = state.keys.map((key, index) => {
                    let y = keyMargin;
                    let x = window.innerWidth * 0.8 * (index / 6);
                    if (rows === 2 && index < 3) {
                        x = window.innerWidth * 0.8 * (index / 3);
                    }
                    if (rows === 2 && index >= 3) {
                        y = y + height * 0.55;
                        x = window.innerWidth * 0.8 * ((index - 3) / 3) + width;
                    }
                    return {
                        ...key,
                        position: {
                            y,
                            x,
                        },
                        startPosition: {
                            y,
                            x,
                        },
                    };
                });
                state.keysArranged = true;
            }
        },
        resetKeys: (state) => {
            state.keys = state.keys.map((key) => {
                return {
                    ...key,
                    position: { ...key.startPosition },
                };
            });
            state.slots = state.slots.map((slot) => {
                return {
                    ...slot,
                    insertedKey: null,
                };
            });
        },
        setHitbox: (
            state,
            action: PayloadAction<{
                id?: number;
                hitboxType: 'slot' | 'key' | 'container';
                boundary: T_Hitbox;
            }>,
        ) => {
            const { hitboxType } = action.payload;
            if (hitboxType === 'slot') {
                const foundSlot = state.slots.find(
                    (slot) => slot.id === action.payload.id,
                );
                if (foundSlot) {
                    state.slots = state.slots.map((slot) => {
                        if (slot.id === action.payload.id) {
                            return { ...slot, hitbox: action.payload.boundary };
                        }
                        return slot;
                    });
                }
                return;
            }
            if (hitboxType === 'container') {
                state.containerHitbox = action.payload.boundary;
            }
        },
        handleKeyDrag: (
            state,
            action: PayloadAction<{
                id: number;
                position: { x: number; y: number };
            }>,
        ) => {
            const { id, position } = action.payload;
            const { x, y } = position;
            const slotUnderKey = findSlotUnderKey({ slots: state.slots, x, y });
            state.slots = state.slots.map((slot) => {
                if (slotUnderKey && slot.id === slotUnderKey.id && !slot.insertedKey) {
                    return { ...slot, keyAboveSlot: true };
                }
                return { ...slot, keyAboveSlot: false };
            });
        },
        handleKeyDragStop: (
            state,
            action: PayloadAction<{
                id: number;
                position: { x: number; y: number };
            }>,
        ) => {
            const { id, position } = action.payload;
            const { x, y } = position;
            const xMargin = state.containerHitbox.xLeft;
            const yMargin = state.containerHitbox.yTop;

            const slotUnderKey = findSlotUnderKey({ slots: state.slots, x, y });
            if (slotUnderKey?.insertedKey !== null) return;
            const keyHeight = state.keyDimensions?.height;
            const insertedKeys = state.slots
                .filter((slot) => slot.insertedKey)
                .map((slot) => slot.insertedKey);
            if (!slotUnderKey && insertedKeys.includes(id)) {
                state.slots = state.slots.map((slot) => {
                    if (slot.insertedKey === id) {
                        return {
                            ...slot,
                            insertedKey: null,
                            keyAboveSlot: false,
                        };
                    }
                    return slot;
                });
            }

            if (!slotUnderKey) {
                state.keys = state.keys.map((key) => {
                    if (key.id === id) {
                        return {
                            ...key,
                            position: {
                                x: key.startPosition.x,
                                y: key.startPosition.y,
                            },
                        };
                    }
                    return key;
                });
            }
            if (slotUnderKey && keyHeight) {
                let slotY = 0;
                state.slots = state.slots.map((slot) => {
                    if (slot.id === slotUnderKey.id) {
                        slotY = slotUnderKey.hitbox.yBottom;
                        return {
                            ...slot,
                            insertedKey: id,
                            keyAboveSlot: false,
                        };
                    }
                    if (slot.insertedKey === id) {
                        return {
                            ...slot,
                            insertedKey: null,
                            keyAboveSlot: false,
                        };
                    }
                    return { ...slot, keyAboveSlot: false };
                });
                const newKeyOrder = state.keys.map((key) => {
                    if (key.id === id) {
                        const { hitbox } = slotUnderKey;
                        return {
                            ...key,
                            position: {
                                x: hitbox.xLeft - xMargin,
                                y: hitbox.yBottom - keyHeight * 0.3 - yMargin,
                            },
                        };
                    }
                    return key;
                });
                state.keys = newKeyOrder;
                return;
            }
            if (
                x < state.containerHitbox.xLeft ||
                x > state.containerHitbox.xRight ||
                y < state.containerHitbox.yTop ||
                y > state.containerHitbox.yBottom
            ) {
                return;
            }
            state.keys = state.keys.map((key) => {
                if (key.id === id && slotUnderKey) {
                    return {
                        ...key,
                        position: {
                            y:
                                y -
                                (state.keyDimensions?.height || 120) / 2 -
                                yMargin +
                                100,
                            x:
                                x -
                                (state.keyDimensions?.width || 120) / 2 -
                                xMargin,
                        },
                    };
                }
                return key;
            });
        },
    },
});

export const {
    activateChrono,
    setKeyDimensions,
    resetKeys,
    resetChrono,
    setHitbox,
    handleKeyDrag,
    handleKeyDragStop,
} = appSlice.actions;

export default appSlice.reducer;
