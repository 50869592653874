import { T_Key } from '../../../types';
import { KeyNew } from './KeyNew';

export const KeysNew = ({ keys }: { keys: T_Key[] }) => {
    return (
        <>
            {keys.map((key: T_Key) => {
                return <KeyNew key={key.id} keyObject={key} />;
            })}
        </>
    );
};
