import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../hooks';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useDoor } from '../hooks/useDoor';
import { SelectionBox } from '../interactions/SelectionBox';
import { setDragScroll, setScroll, toggleMouseScroll } from '../store/appState';

export const Arcade = () => {
    const arcadeRef = useRef<any>(null);
    const scrollRef = useRef<any>(null);
    const mouseScroll = useAppSelector((state) => state.appState.inScroll);
    const doorOpen = useDoor();
    const dispatch = useAppDispatch();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const scroll = useAppSelector((state) => state.appState.scroll);

    useEffect(() => {
        if (!arcadeRef || !arcadeRef.current || !scroll) return;
        setDimensions({
            width: arcadeRef.current.width,
            height: arcadeRef.current.height,
        });
        scrollRef.current.scrollTop = scroll.height;
        scrollRef.current.scrollLeft = scroll.width;
    }, [arcadeRef, scroll]);

    return (
        <div
            id="arcade"
            className="arcade"
            onScroll={(e: any) => {
                const scroll = {
                    height: scrollRef.current.scrollTop,
                    width: scrollRef.current.scrollLeft,
                };
                dispatch(setScroll(scroll));
            }}
            onMouseDown={(e) => {
                dispatch(toggleMouseScroll(true));
            }}
            onMouseUp={(e) => {
                dispatch(toggleMouseScroll(false));
            }}
            onMouseMove={(e) => {
                if (!mouseScroll || isNaN(e.movementX) || isNaN(e.movementY))
                    return;
                dispatch(setDragScroll({ x: e.movementX, y: e.movementY }));
            }}
            ref={scrollRef}
        >
            <picture>
                <source srcSet="images/arcademachines.png" type="image/png" />
                <img
                    className="zero glow"
                    src="images/arcademachines.png"
                    width="2480"
                    height="2644"
                    loading="lazy"
                    alt="Arcade"
                    ref={arcadeRef}
                    style={{ clipPath: doorOpen ? 'inset(0 0 15% 0)' : '' }}
                />
            </picture>
            <picture>
                <source srcSet="images/arcade.webp" type="image/webp" />
                <source srcSet="images/arcade.jpg" type="image/jpg" />
                <img
                    src="images/arcade.jpg"
                    width="2480"
                    height="2644"
                    loading="lazy"
                    alt="Arcade"
                    ref={arcadeRef}
                />
            </picture>

            <div
                id="arcade-clickspots"
                style={{
                    width: dimensions.width,
                    height: dimensions.height,
                }}
                className="clickspots"
            >
                <SelectionBox id={'letris'} screen={'LETRIS'} />
                <SelectionBox id={'placman'} screen={'PLAC'} />
                <SelectionBox id={'rario'} screen={'RARIO'} />
                <SelectionBox doorOpen={doorOpen} id={'newDoorOpen'} />
            </div>
        </div>
    );
};
