import { configureStore } from '@reduxjs/toolkit';

import appStateReducer from './appState/slice';
import chronoStateReducer from './chronoState/slice';

const store = configureStore({
    reducer: {
        appState: appStateReducer,
        chronoState: chronoStateReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
export {};
