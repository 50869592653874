import React from 'react';

export const HintWrapper = (props: {
    id: number;
    children?: React.ReactNode;
}) => {
    const { id, children } = props;
    return (
        <div
           className="hint-wrapper"
        ><div className="hint-inner-wrapper">
            <h3
              className="hint-text"
            >
                Hint {id}
            </h3></div>
            {children}</div>
    
    );
};
