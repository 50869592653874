export const YellowBlack = () => {
    return (
        <>
            <div className="yellow-black">
                <img
                    className="yellow-black-image"
                    src="/images/pattern-l.png"
                />
                <img
                    className="yellow-black-image"
                    src="/images/pattern-r.png"
                />
            </div>
        </>
    );
};
