import { T_Slot } from '../../../types';
import { useAppSelector } from '../../hooks';

export const SlotEntry = ({ slot }: { slot: T_Slot }) => {
    const keyHeight = useAppSelector(
        (state) => state.chronoState?.keyDimensions?.height,
    );
    return (
        <>
            <div
                className="chronodecoder-slot"
                style={{
                    height: (keyHeight || 100) * 0.58,
                    backgroundColor: slot.keyAboveSlot ? '#59594b' : '#1d1d1b',
                }}
            >
                {/* {slot.keyAboveSlot ? 'TEST2' : 'TEST'} */}
            </div>
        </>
    );
};
