import { Switch } from './Rario/Switch';

export const Rario = () => {
    return (
        <>
            <div className="wrapper-rario">
                <div className="screen-rario">
                    <Switch />
                    <img
                        src="/images/rario_bg.png"
                        className="screen-rario-bg"
                        width="100%"
                        height="auto"
                    />
                </div>
            </div>
        </>
    );
};
