import { useEffect, useRef, useState } from 'react';
import { Hint } from './hints/Hint';
export const Hints = () => {
    const scrollRef = useRef<any>(null);
    const [mouseScroll, setMouseScroll] = useState<boolean>(false);
    const [xScroll, setXScroll] = useState<number>(0);
    useEffect(() => {
        if (!scrollRef?.current) return;
        scrollRef.current.scrollLeft = xScroll;
    }, [xScroll]);
    const maxScroll = scrollRef?.current?.scrollWidth;
    const hints = [
        {
            id: 1,
            text: (
                <>
                    Volg de elektriciteitskabels vanaf speelkast ‘Magic Portal’
                    in de Arcadehal en de kelder voor de juiste volgorde van de
                    code.
                </>
            ),
        },
        {
            id: 2,
            text: (
                <>
                    <u>R.ario</u>: bereik de uitgang zonder te botsen met een
                    monster. Er is maar 1 juiste route.
                    <br /> <u>Letris</u>: de blokken vallen in aangegeven
                    volgorde naar beneden. Voor de oplossing lees je de letters
                    in de blokken als doorlopende zin.
                </>
            ),
        },
        {
            id: 3,
            text: (
                <>
                    <u>Plac-man</u>: tel vanaf Plac-man de stipjes, de langst
                    mogelijke route heeft de vorm van een cijfer.
                    <br /> <u>Cupz</u>: de oplossing is te vinden op de deur en
                    op de bovenkant van de machine.
                </>
            ),
        },
        {
            id: 4,
            text: (
                <>
                    Uit elk van de 4 spellen komt een cijfer van 1 tot 6. Volg
                    de kabels vanaf de speelkast <u>Magic Portal</u> in de hal
                    en in de kelder.
                </>
            ),
        },
    ];
    return (
        <div className="hints-main-wrapper">
            <div
                onMouseDown={(e) => {
                    setMouseScroll(true);
                }}
                onMouseUp={(e) => {
                    setMouseScroll(false);
                }}
                onMouseMove={(e) => {
                    if (
                        !mouseScroll ||
                        isNaN(e.movementX) ||
                        isNaN(e.movementY)
                    )
                        return;
                    setXScroll((prev) => {
                        const next = prev - e.movementX;
                        if (next < 0) return 0;
                        if (next > maxScroll) return maxScroll - 1;
                        return next;
                    });
                }}
                ref={scrollRef}
                id="slider"
                className="hints-inner-wrapper"
            >
                {hints.map((value: { id: number; text: JSX.Element }) => {
                    return (
                        <Hint key={value.id} id={value.id} text={value.text} />
                    );
                })}
            </div>
        </div>
    );
};
