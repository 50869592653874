import { T_LetrisLetters } from '../../types';

export const getImageFromBlock = (blockId: T_LetrisLetters) => {
    switch (blockId) {
        case 'ANOO':
            return 'images/block_anoo.png';
        case 'FTTW':
            return 'images/block_fttw.png';
        case 'HJES':
            return 'images/block_hjes.png';
        case 'JUIE':
            return 'images/block_juie.png';
        case 'VIIS':
            return 'images/block_viis.png';
        default:
            return 'images/block_anoo.png';
    }
};
