export const Border = ({
    borderRef,
}: {
    borderRef: React.RefObject<HTMLDivElement>;
}) => {
    return (
        <>
            <div
                ref={borderRef}
                style={{
                    position: 'absolute',
                    top: 0,
                    left: '64%',
                    height: '100%',
                    width: '5px',
                    display: 'flex',
                }}
            ></div>
        </>
    );
};
