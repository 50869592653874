import { useEffect, useRef, useState } from 'react';
import { T_Slot } from '../../../types';
import { useAppDispatch, useAppSelector } from '../../hooks';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import {
    declarePreVictory,
    declareVictory,
    decreaseMinute,
} from '../../store/appState';
import { resetChrono, resetKeys } from '../../store/chronoState/slice';
import { Container } from './Container';
import { KeysNew } from './KeysNew';
import { Slots } from './Slots';
import { useHitboxSetter } from './hooks/useHitboxSetter';

export const Chronodecoder = () => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [firstRender, setFirstRender] = useState(true);

    const slots = useAppSelector((state) => state.chronoState.slots);
    const keys = useAppSelector((state) => state.chronoState.keys);
    const keysArranged = useAppSelector(
        (state) => state.chronoState.keysArranged,
    );
    const windowDimensions = useWindowDimensions();
    const [initialDimensions, setInitialDimensions] = useState<{
        width: number;
        height: number;
    }>(windowDimensions);
    const dispatch = useAppDispatch();
    const chronoReset = useAppSelector(
        (state) => state.chronoState.chronoReset,
    );
    useEffect(() => {
        const code = slots
            .map((slot: T_Slot) => {
                return slot.insertedKey || 0;
            })
            .join('');
        if (code === '6153') {
            dispatch(declarePreVictory());
            const timer = setTimeout(() => {
                dispatch(declareVictory());
            }, 3000);
            return () => {
                clearTimeout(timer);
            };
        }
        if (!code.includes('0')) {
            dispatch(decreaseMinute());
            dispatch(resetKeys());
        }
    }, [slots]);
    useHitboxSetter({
        ref: containerRef,
        hitboxType: 'container',
        dependencies: [chronoReset],
    });
    useEffect(() => {
        if (firstRender || windowDimensions.width === 0) {
            setFirstRender(false);
            return;
        }
        if (initialDimensions.height === 0) {
            setInitialDimensions(windowDimensions);
            return;
        }
        if (initialDimensions.width !== windowDimensions.width) {
            dispatch(resetChrono());
        }
    }, [windowDimensions]);

    return (
        <Container reference={containerRef}>
            {keysArranged && !chronoReset && (
                <>
                    <div
                        style={{
                            position: 'absolute',
                            width: '100%',
                            height: '20%',
                            backgroundColor: 'rgba(100 116 139, 0.5)',
                        }}
                    >
                        <KeysNew keys={keys} />
                    </div>
                </>
            )}

            <Slots slots={slots} />
        </Container>
    );
};
