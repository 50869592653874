import { T_Screen } from '../../types';

export const getImageFromScreen = (screen: T_Screen) => {
    switch (screen) {
        case 'LETRIS':
            return '/images/letris_bg.png';
        case 'PLAC':
            return '/images/placman_bg.png';
        case 'RARIO':
            return '/images/rario.png';
        default:
            return '/images/letris.png';
    }
};
