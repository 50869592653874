import { useSound } from "../hooks/useSound"

export const SoundIcon = () => {
  const soundSetting = useSound()
  return (
    <svg id="mute-unmute" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 24.5">
         <defs>{soundSetting &&
         <style>{`.speaker{fill:#e00046;}`}</style>
         }
         {soundSetting ||
         <style>{`.speaker{fill:#FFFFFF;}`}</style>
         }
          
        </defs>
      <path className="speaker" id="speaker" d="m17.5,0c0,.35-.02.71-.02,1.06,0,7.54,0,15.09,0,22.63,0,.27.01.54.02.81h-3.5v-3.54h-3.49v-3.49h-3.51v-1.97H0v-6.5h7.04v-2.01h3.51v-3.49h3.45V0C15.17,0,16.33,0,17.5,0Z" />
   
      {soundSetting && <g id="cross">
        <path className="speaker" d="m43,7c-1.17,0-2.34,0-3.51,0v-3.51h3.51v3.5Z" />
        <path className="speaker" d="m39.49,17.49c1.17,0,2.34,0,3.51,0v3.5h-3.51v-3.51Z" />
        <path className="speaker" d="m29,6.98h-3.45v-3.43h3.42v3.45l.02-.02Z" />
        <path className="speaker" d="m28.98,7h3.51c0,1.19,0,2.34,0,3.49,0,0,.02-.02.02-.02h-3.51c0-1.18,0-2.34,0-3.49,0,0-.02.02-.02.02Z" />
        <path className="speaker" d="m39.51,6.99v3.51h-3.51v-3.51h3.51Z" />
        <path className="speaker" d="m36.01,10.49c0,1.17,0,2.34,0,3.51-1.17,0-2.33,0-3.5-.01,0,0,.02.02.02.02,0-1.18-.01-2.36-.02-3.55,0,0-.02.02-.02.02,1.17,0,2.34,0,3.51,0Z" />
        <path className="speaker" d="m29.02,17.5v-3.49c1.21,0,2.36,0,3.51,0,0,0-.02-.02-.02-.02,0,1.13,0,2.26,0,3.49h-3.51l.02.02Z" />
        <path className="speaker" d="m35.99,13.99h3.51v3.51h-3.51v-3.51Z" />
        <path className="speaker" d="m29,17.48v3.46h-3.43v-3.43c1.15,0,2.3,0,3.45,0,0,0-.02-.02-.02-.02Z" />
      </g>}
    </svg>)
}