import { T_SpawnPosition } from '../../../types';

// export const initialLetterBlocks: T_PositionedLetrisBlock[] = [
//     { id: 'ANOO', left: '72%', top: '20.5%' },

//     { id: 'HJES', left: '75.5%', top: '31.9%' },
//     { id: 'FTTW', left: '73%', top: '45%' },
//     { id: 'JUIE', left: '72%', top: '68%' },
//     { id: 'VIIS', left: '74.5%', top: '79%' },
// ];
export const spawnPositions: T_SpawnPosition[] = [
    { id: 'ANOO', left: '72%', top: '20.5%' },
    { id: 'HJES', left: '75.5%', top: '31.9%' },
    { id: 'FTTW', left: '73%', top: '45%' },
    { id: 'JUIE', left: '72%', top: '68%' },
    { id: 'VIIS', left: '74.5%', top: '79%' },
];
