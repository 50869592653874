export const sounds = {
    victory: './audio/victory.wav',
    defeat: './audio/defeat.wav',
    minuteCountdown: './audio/minuteCountdown.wav',
    hint: './audio/hint.mp3',
    chronoAmbient: './audio/chronoAmbient.mp3',
    menuAmbient: './audio/menuAmbient.mp3',
    demoAmbient: './audio/demoAmbient.mp3',
    wrong: './audio/wrong.wav',
};

export type T_SoundKey = keyof typeof sounds;
