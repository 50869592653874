import { T_LetrisLetters } from '../../types';
import { spawnPositions } from '../screens/Letris/initialLetterBlocks';

export const getStartPositions = (
    id: T_LetrisLetters,
): { top: string; left: string } => {
    return (
        spawnPositions.find((block) => block.id === id) || { top: '', left: '' }
    );
};
