import { useAppSelector } from '../../hooks';

export const SlotFrontPart = () => {
    const keyDimensions = useAppSelector(
        (state) => state.chronoState?.keyDimensions,
    );
    const keyHeight = useAppSelector(
        (state) => state.chronoState?.keyDimensions?.height,
    );
    return (
        <>
            <div
                style={{
                    position: 'absolute',
                    bottom: 0,
                    height: keyHeight && keyHeight * 0.52,
                    width: '100%',
                    backgroundColor: '#2f2e2d',
                    zIndex: 30,
                    borderBottom: '2px solid #000',
                }}
            />
        </>
    );
};
