import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type {
    T_AppState,
    T_LetrisLetters,
    T_Page,
    T_PlacmanDraw,
    T_Room,
    T_Screen,
    T_Slots,
} from '../../../types/';
import { getTranslateCoordinates } from '../../functions/getTranslateCoordinates';
import { muteSound } from '../../functions/muteSound';
import { playSound as _playSound } from '../../functions/playSound';
import { spawnPositions } from '../../screens/Letris/initialLetterBlocks';
import { T_SoundKey, sounds } from '../../sounds';

const initialState: T_AppState = {
    page: 'Start',
    room: null,
    screen: null,
    unlockedHint: 1,
    timer: 900,
    inScroll: false,
    scroll: { height: 100, width: 100 },
    hintScroll: 0,
    inHintScroll: false,
    doorOpen: false,
    letrisDraw: [],
    letrisBlocks: spawnPositions.map((spawnPosition) => {
        return {
            id: spawnPosition.id,
            x: 0,
            y: 0,
        };
    }),
    letrisBlocksFinal: [],
    letrisReset: false,
    letrisDrag: null,
    placMan: [],
    placManLoaded: false,
    touchPosition: { x: 0, y: 0 },
    soundSettings: {
        volume: 0.5,
        muted: true,
    },
    slots: {
        slot1: 0,
        slot2: 0,
        slot3: 0,
        slot4: 0,
    },
    preVictory: false,
    defeated: false,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        toggleMouseScroll: (state, action: PayloadAction<boolean>) => {
            state.inScroll = action.payload;
        },
        setRoom: (state, action: PayloadAction<T_Room>) => {
            if (state.page === 'Intro') {
                state.page = 'InGame';
                state.room = 'Arcade';
            }
            if (!state.soundSettings.muted) {
                if (action.payload === 'CHRONO') {
                    muteSound();
                    _playSound(
                        sounds.chronoAmbient as T_SoundKey,
                        900 - state.timer,
                    );
                }
                if (state.room === 'CHRONO' && action.payload === 'Arcade') {
                    muteSound();
                    _playSound(
                        sounds.demoAmbient as T_SoundKey,
                        900 - state.timer,
                    );
                }
            }
            state.room = action.payload;
            if (action.payload === 'CHRONO') {
                state.page = 'InGame';
                state.screen = null;
            }
        },
        decreaseMinute: (state) => {
            if (!state.soundSettings.muted) {
                _playSound(sounds.wrong as T_SoundKey);
            }
            state.timer = state.timer - 60;
        },
        openDoor: (state) => {
            state.doorOpen = !state.doorOpen;
        },
        setPage: (state, action: PayloadAction<T_Page>) => {
            if (action.payload === 'InGame') {
                state.room = 'Arcade';
                state.screen = null;
            }
            state.page = action.payload;
        },
        setScreen: (state, action: PayloadAction<T_Screen>) => {
            state.page = 'InScreen';
            state.screen = action.payload;
        },
        leaveScreen: (state) => {
            state.page = 'InGame';
            state.room = 'Arcade';
            state.screen = null;
        },
        setHint: (state, action: PayloadAction<1 | 2 | 3>) => {
            state.unlockedHint = action.payload;
        },

        setScroll: (
            state,
            action: PayloadAction<{ height: number; width: number }>,
        ) => {
            state.scroll = action.payload;
        },
        setDragScroll: (
            state,
            action: PayloadAction<{ x: number; y: number }>,
        ) => {
            const { x, y } = action.payload;
            if (y < 0) {
                state.scroll = {
                    ...state.scroll,
                    height: state.scroll.height - y,
                };
            }
            if (y > 0) {
                state.scroll = {
                    ...state.scroll,
                    height: state.scroll.height - y,
                };
            }
            if (x < 0) {
                state.scroll = {
                    ...state.scroll,
                    width: state.scroll.width - x,
                };
            }
            if (x > 0) {
                state.scroll = {
                    ...state.scroll,
                    width: state.scroll.width - x,
                };
            }
        },
        decreaseTimer: (state) => {
            if (state.preVictory) return;
            state.letrisReset = false;
            if (state.timer <= 600 && state.unlockedHint < 2) {
                state.unlockedHint = 2;
            }
            if (state.timer <= 300 && state.unlockedHint < 3) {
                state.unlockedHint = 3;
            }
            if (state.timer <= 0 && !state.defeated) {
                state.unlockedHint = 4;
                state.defeated = true;
                if (!state.soundSettings.muted) {
                    _playSound(sounds.defeat as T_SoundKey);
                }
            }
            if (state.timer === 300 || state.timer === 600) {
                if (!state.soundSettings.muted) {
                    _playSound(sounds.hint as T_SoundKey);
                }
            }
            state.timer = state.timer - 0.25;
        },
        startScroll: (state) => {
            state.inHintScroll = true;
        },
        endScroll: (state) => {
            state.inHintScroll = false;
        },
        increaseScroll: (state) => {
            if (state.hintScroll >= 150) return;
            state.hintScroll = state.hintScroll + 0.5;
        },
        decreaseScroll: (state) => {
            if (state.hintScroll <= 0) return;
            state.hintScroll = state.hintScroll - 0.5;
        },
        resetLetris: (state) => {
            state.letrisReset = true;
            state.letrisBlocks = spawnPositions.map((block) => ({
                ...block,
                x: 0,
                y: 0,
            }));
        },

        playSound: (state, action: PayloadAction<T_SoundKey>) => {
            if (state.soundSettings.muted) return;
            _playSound(action.payload);
        },
        playTimedSound: (state, action: PayloadAction<T_SoundKey>) => {
            if (state.soundSettings.muted) return;
            muteSound();
            _playSound(action.payload, 900 - state.timer);
        },
        handleMute: (state) => {
            if (!state.soundSettings.muted) {
                muteSound();
            }
            if (state.soundSettings.muted) {
                if (state.room === null) {
                    _playSound(
                        sounds.menuAmbient as T_SoundKey,
                        900 - state.timer,
                    );
                }
                if (state.room === 'CHRONO') {
                    _playSound(
                        sounds.chronoAmbient as T_SoundKey,
                        900 - state.timer,
                    );
                }
                if (state.room && state.room !== 'CHRONO') {
                    _playSound(
                        sounds.demoAmbient as T_SoundKey,
                        900 - state.timer,
                    );
                }
            }
            state.soundSettings.muted = !state.soundSettings.muted;
        },
        declareVictory: (state) => {
            state.page = 'End';
            state.room = null;
            state.screen = null;
        },
        declarePreVictory: (state) => {
            if (!state.soundSettings.muted) {
                muteSound();
                _playSound(sounds.victory as T_SoundKey);
            }
            state.preVictory = true;
        },
        setSlots(state, action: PayloadAction<T_Slots>) {
            state.slots = action.payload;
        },
        setLetrisDrag(state, action: PayloadAction<T_LetrisLetters | null>) {
            state.letrisDrag = action.payload;
        },
        addPlacManDraw(state, action: PayloadAction<T_PlacmanDraw>) {
            state.placMan = [...state.placMan, action.payload];
        },

        resetPlacManDraw(state) {
            state.placMan = [];
        },
        setPlacManLoaded(state, action: PayloadAction<boolean>) {
            state.placManLoaded = action.payload;
        },
        dragBlock(
            state,
            action: PayloadAction<{
                transform: string | undefined;
                id: T_LetrisLetters;
            }>,
        ) {
            const { id, transform } = action.payload;
            if (!transform) return;
            state.letrisBlocks = state.letrisBlocks.map((block) => {
                if (block.id === id) {
                    const { x, y } = getTranslateCoordinates(transform);
                    return { ...block, x, y };
                }
                return block;
            });
        },
    },
});

export const {
    setRoom,
    setPage,
    setScreen,
    setHint,
    setScroll,
    leaveScreen,
    decreaseTimer,
    resetLetris,
    startScroll,
    endScroll,
    increaseScroll,
    decreaseScroll,
    playSound,
    handleMute,
    playTimedSound,
    declareVictory,
    declarePreVictory,
    setSlots,
    setDragScroll,
    openDoor,
    decreaseMinute,
    toggleMouseScroll,
    setLetrisDrag,
    dragBlock,
    addPlacManDraw,
    resetPlacManDraw,
    setPlacManLoaded,
} = appSlice.actions;

export default appSlice.reducer;
