export const Logo = () => {
    return (
      <svg id="Logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 167.6 38.87">
        <defs>
          <style>{`.cls-1{fill:#fff;}`}</style>
        </defs>
        <path className="cls-1" d="m97.24,27.27h2.48v-7.62h-2.48v-3.6c0-2.01.67-3.01,2.01-3.01.69,0,1.17.22,1.44.66.27.44.4,1.22.4,2.35v18.1h8.6V15.47c0-3.73-.81-6.48-2.43-8.27-1.62-1.79-4.13-2.68-7.52-2.68s-6.24.98-8.18,2.95c-1.94,1.97-2.91,4.73-2.91,8.29v18.38h8.6v-6.88Z" />
        <path className="cls-1" d="m123.49,13.55h2.9c4.93,0,7.39,1.97,7.39,5.91,0,4.19-2.51,6.29-7.51,6.29h-1.38v8.49c1.19.06,2.04.09,2.56.09,4.45,0,8.08-1.38,10.9-4.15,2.82-2.77,4.24-6.34,4.24-10.71s-1.65-8.19-4.95-10.86c-2.78-2.23-6.63-3.35-11.54-3.35h-11.2v28.9h8.6V13.55Z" />
        <path className="cls-1" d="m70.03,34.87c4.24,0,7.67-1.27,10.3-3.81,2.63-2.54,4.25-6.14,4.84-10.8l-9-.23c-.2,2.03-.84,3.59-1.92,4.68-1.08,1.09-2.53,1.63-4.35,1.63s-3.28-.62-4.45-1.85c-1.17-1.24-1.76-2.8-1.76-4.69s.6-3.58,1.79-4.85c1.19-1.27,2.71-1.9,4.54-1.9,2.88,0,4.84,1.58,5.87,4.74l9.21-.61c-.82-3.56-2.22-6.33-4.19-8.32-2.85-2.88-6.41-4.32-10.67-4.32s-7.98,1.47-10.94,4.4c-2.96,2.94-4.43,6.56-4.43,10.86s1.45,7.86,4.35,10.74c2.9,2.89,6.5,4.33,10.81,4.33" />
        <path className="cls-1" d="m155.84,34.15h6.75v-8.26h-5.78c-1,0-1.67-.09-1.99-.28-.32-.19-.49-.59-.49-1.2,0-.54.16-.92.48-1.14.32-.22.86-.34,1.62-.34h5.61v-6.44h-4.74c-.9,0-1.53-.11-1.88-.33-.35-.22-.53-.61-.53-1.18,0-.96.74-1.44,2.22-1.44h5.48V5.25h-7.47c-2.88,0-5.11.76-6.7,2.29-1.59,1.52-2.38,3.67-2.38,6.44v11.33c0,2.86.84,5.06,2.52,6.57,1.68,1.52,4.11,2.28,7.28,2.28" />
        <path className="cls-1" d="m5.02,15.77v18.38h8.6v-6.88h2.48v-7.62h-2.48v-3.6c0-2.01.67-3.01,2.01-3.01.69,0,1.17.22,1.44.66.27.44.4,1.22.4,2.35v18.1h8.6V15.47c0-3.73-.81-6.48-2.43-8.27-1.62-1.79-4.13-2.68-7.52-2.68s-6.24.98-8.18,2.95c-1.94,1.97-2.91,4.73-2.91,8.29" />
        <path className="cls-1" d="m39.29,13.02h1.52c2.09,0,3.13.68,3.13,2.05,0,1.55-.9,2.33-2.71,2.33h-.68v7.43l4.17,9.32h9.02l-4.95-11.92c2.56-2.01,3.83-4.47,3.83-7.39s-1.03-5.23-3.09-7.09c-1.85-1.67-4.45-2.5-7.81-2.5h-11.03v28.9h8.6V13.02Z" />
        <path className="cls-1" d="m164.12,35.39H3.48V14.13c0-5.87,4.78-10.65,10.65-10.65h149.99v31.91Zm0-35.39H14.13C6.34,0,0,6.34,0,14.13v24.74h167.6V0h-3.48Z" />
      </svg>
    );
  };
  