import { Lock } from '../../components/Lock';
import { formatTime } from '../../functions';
import { useAppSelector } from '../../hooks';
import { HintWrapper } from './HintWrapper';

export const LockedHint = (props: { id: number }) => {
    const { id } = props;
    const time = useAppSelector((state) => state.appState.timer);
    const unlockedHint = useAppSelector((state) => state.appState.unlockedHint);
    const formattedTime = formatTime(
        (time % 300) + 300 * (id - 1) - 300 * unlockedHint,
    );
    return (
        <HintWrapper id={id}>
            <div className="lock">
                <div className="lock-inner">
                    <Lock />
                </div>
            </div>
            <div className="hint-top-text-wrapper">
                <h3 className="hint-top-text">{formattedTime}</h3>
            </div>
        </HintWrapper>
    );
};
